var swiper = new Swiper(".mySwiper", {
  slidesPerView: 4,
  spaceBetween: 24,

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    1300: {
      slidesPerView: 4,
    },
    1170: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2.5,
    },
    400: {
      slidesPerView: 2.25,
    },
    300: {
      slidesPerView: 1.25,
    },
  },
});

var swiper = new Swiper(".myLibrary", {
  slidesPerView: 3,
  spaceBetween: 24,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    1300: {
      slidesPerView: 3,
    },
    1170: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2.5,
    },
    400: {
      slidesPerView: 2.2,
      spaceBetween: 12,
    },
    300: {
      slidesPerView: 1.2,
      spaceBetween: 12,
    },
  },
});

var slider = new Swiper(".gallery-slider", {
  slidesPerView: 1,
  centeredSlides: true,
  loop: false,
  loopedSlides: 6,
  spaceBetween: 10,
  clickable: true,
});
var thumbs = new Swiper(".gallery-thumbs", {
  slidesPerView: "auto",
  spaceBetween: 10,
  direction: "vertical",
  slideToClickedSlide: true,
  loop: false,
  centeredSlides: true,
});
slider.controller.control = thumbs;
thumbs.controller.control = slider;
