import "intersection-observer";
import Headroom from "headroom.js";
import sal from "sal.js";
import GLightbox from "glightbox";
import "./swiper.js";
import * as bootstrap from "bootstrap";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

flatpickr(".flatPicker", {
  locale: "en",
});

// loadClass
const theApp = document.getElementById("theApp");
theApp.classList.add("is-loaded");

// Sticky Header
const theHeader = document.getElementById("theHeader");
if (theHeader) {
  var headroom = new Headroom(theHeader, {
    offset: 100,
    classes: {
      initial: "is-sticky",
      // when scrolling up
      pinned: "is-pinned",
      // when scrolling down
      unpinned: "is-unpinned",
      // when above offset
      top: "is-top",
      // when below offset
      notTop: "is-not-top",
      // when at bottom of scoll area
      bottom: "is-bottom",
      // when not at bottom of scroll area
      notBottom: "is-not-bottom",
    },
  });
  headroom.init();
}

// Sal.js
sal({
  threshold: 0.1,
  once: true,
});

// #navOverlay
const navOverlay = document.querySelectorAll(".navOverlay")[0];
const navOverlayClose = document.querySelectorAll(".navOverlay-close");
const navOverlayOpen = document.querySelectorAll(".navOverlay-open");
if (navOverlayClose && navOverlay) {
  navOverlayClose.forEach((el) => {
    el.addEventListener("click", (event) => {
      navOverlay.classList.remove("is-active");
    });
  });
}
if (navOverlayOpen && navOverlay) {
  navOverlayOpen.forEach((el) => {
    el.addEventListener("click", (event) => {
      if (navOverlay.classList.contains("is-active")) {
        navOverlay.classList.remove("is-active");
        navOverlayOpen[0].classList.remove("is-active");
      } else {
        navOverlay.classList.add("is-active");
        navOverlayOpen[0].classList.add("is-active");
      }
    });
  });
}

// LazyLoad Images
window.addEventListener("load", (event) => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const lazyImage = entry.target.querySelector("img[data-src]");
        if (entry.intersectionRatio > 0) {
          lazyImage.src = lazyImage.dataset.src;

          lazyImage.onload = function () {
            entry.target.classList.add("inViewJs-active");
            observer.unobserve(entry.target);
          };
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );
  });

  inViewElms.forEach((elm) => {
    observer.observe(elm);
  });
});



//Edit Image

document.addEventListener("DOMContentLoaded", function () {
  let slider = document.querySelector("#slider"),
    bar = document.querySelector("#zoom-bar-cont"),
    img = document.querySelector("#scaleImg");

  //Min-max function to set up slider boundaries
  function valBetween(v, min, max) {
    return Math.min(max, Math.max(min, v));
  }
  //function to define zoom bottom half and top half ranges
  let setZoomVal = function (val, percent, botVal = true) {
    return botVal
      ? valBetween((percent * (1 - val)) / 0.5, 0, 1 - val) + val
      : valBetween(percent * (val * 2 - 2), val - 1, val * 2 - 2) - (val - 2);
  };
  function sliderInit(e) {
    e.preventDefault();
    document.addEventListener("mousemove", sliderDrag);
    document.addEventListener("mouseup", (e) => {
      document.removeEventListener("mousemove", sliderDrag);
    });
  }

  function sliderDrag(e) {
    //Variables aren't set as global to account for possible window resize
    let x0 = bar.getBoundingClientRect().left;
    let width = bar.offsetWidth;
    let dX = e.clientX - x0;
    let dPercent = dX / width;
    let sliderOff = slider.offsetLeft;

    slider.style.left = valBetween((dX / width) * 100, 0, 100) + "%";

    img.style.transform =
      "translate3d(-50%,-50%,0) scale(" +
      (sliderOff / width < 0.5
        ? setZoomVal(0.4, dPercent)
        : setZoomVal(3, dPercent, false));
    +")";
  }

  slider.addEventListener("mousedown", sliderInit);
  bar.addEventListener("mousedown", sliderInit);
});

//phone city

const phoneInput = document.querySelector("#validationPhone");
const phoneInputWork = document.querySelector("#validationPhoneWork");

// Initialize intlTelInput
const iti = window.intlTelInput(phoneInput, {
  utilsScript:
    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
  initialCountry: "AE",
});

// Initialize intlTelInput
const it = window.intlTelInput(phoneInputWork, {
  utilsScript:
    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
  initialCountry: "AE",
});




// lihgtBox
const lightbox = GLightbox({ touchNavigation: true });

var myInput = document.getElementById("password"),
  myIcon = document.getElementById("icon");

myIcon.onclick = function () {
  if (myIcon.classList.contains("fa-eye-slash")) {
    this.classList.toggle("fa-eye");
    this.classList.toggle("fa-eye-slash");
    myInput.setAttribute("type", "text");
  } else {
    myInput.setAttribute("type", "password");
    this.classList.toggle("fa-eye-slash");
    this.classList.toggle("fa-eye");
  }
};

var myInputPassword = document.getElementById("passwordInput"),
  myIconPassword = document.getElementById("iconPassword");

myIconPassword.onclick = function () {
  if (myIconPassword.classList.contains("fa-eye-slash")) {
    this.classList.toggle("fa-eye");
    this.classList.toggle("fa-eye-slash");
    myInputPassword.setAttribute("type", "text");
  } else {
    myInputPassword.setAttribute("type", "password");
    this.classList.toggle("fa-eye-slash");
    this.classList.toggle("fa-eye");
  }
};

var myInputConfirm = document.getElementById("passwordConfirm"),
  myIconConfirm = document.getElementById("iconConfirm");

myIconConfirm.onclick = function () {
  if (myIconConfirm.classList.contains("fa-eye-slash")) {
    this.classList.toggle("fa-eye");
    this.classList.toggle("fa-eye-slash");
    myInputConfirm.setAttribute("type", "text");
  } else {
    myInputConfirm.setAttribute("type", "password");
    this.classList.toggle("fa-eye-slash");
    this.classList.toggle("fa-eye");
  }
};

const forms = document.querySelectorAll(".needs-validation");

// Loop over them and prevent submission
Array.from(forms).forEach((form) => {
  form.addEventListener(
    "submit",
    (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add("was-validated");
    },
    false
  );
});

// DatePicker
flatpickr(".flatPicker", {
  locale: "ar",
});
